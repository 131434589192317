import { useState } from "react";
import Footer from "../../components/Footer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import api from "../../api/api";
import { toast } from "react-toastify";
import { Trash2 } from "react-feather";

const CourseRegDetails = () => {
  const [loading, setloading] = useState(false);
  const [level, setLevel] = useState("");
  const [session, setSession] = useState("");
  const [registeredShow, setRegisteredShow] = useState(false);
  const [registeredCourses, setRegisteredCourses] = useState([]);
  const [student, setStudent] = useState([]);

  const navigate = useNavigate();
  const { state } = useLocation();

  const totalRegisteredUnits = registeredCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const getRegisteredCourses = async () => {
    if (!level || !session)
      return toast.warning("Please select level and session");
    setloading(true);
    try {
      const { data } = await api.post("/courses/get-student-reg-courses", {
        registration_level: level,
        session,
        student: state,
      });
      console.log(data);
      setRegisteredCourses(data.course_data);
      setStudent(data.student_data);
      setRegisteredShow(true);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const deleteFirstSemesterCourses = async () => {
    try {
      setloading(true);
      const { data } = await api.delete(`/courses/wipe/${level}/1/${state}/`);
      getRegisteredCourses();
      toast.success("First semester courses deleted");
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const deleteSecondSemesterCourses = async () => {
    try {
      setloading(true);
      const { data } = await api.delete(`/courses/wipe/${level}/2/${state}/`);
      getRegisteredCourses();
      toast.success("Second semester courses deleted");
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  if (!state) {
    return (
      <Navigate
        to={{
          pathname: `/course-modification`,
          state: { from: state },
        }}
        replace
      />
    );
  }
  return (
    <div class="page-wrapper">
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Registration</li>
                  </ol>
                </div>
                <h4 class="page-title">Registration</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          <div class="container">
                            <div class="form-group row">
                              <div class="col-md-6">
                                <label>Select Registration Level</label>
                                <select
                                  class="form-control mb-3"
                                  value={level}
                                  onChange={(e) => setLevel(e.target.value)}
                                >
                                  <option></option>
                                  <option>100</option>
                                  <option>200</option>
                                  <option>300</option>
                                  <option>400</option>
                                  <option>500</option>
                                </select>
                                {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                              </div>
                              <div class="col-md-6">
                                <label>Select Session</label>
                                <select
                                  class="form-control mb-3"
                                  value={session}
                                  onChange={(e) => setSession(e.target.value)}
                                >
                                  <option></option>
                                  <option>2018/2019</option>
                                  <option>2019/2020</option>
                                  <option>2020/2021</option>
                                  <option>2021/2022</option>
                                  <option>2022/2023</option>
                                  <option>2023/2024</option>
                                  <option>2024/2025</option>
                                </select>
                                {/* <button className="btn dspg-success mr-1">
                                  Register
                                </button> */}
                              </div>
                            </div>
                            <>
                              <div class="form-group mb-3">
                                <button
                                  class="btn btn-gradient-primary btn-sm  mb-0"
                                  disabled={loading}
                                  onClick={getRegisteredCourses}
                                >
                                  View Registered Courses
                                </button>
                              </div>
                              <hr />

                              <hr />
                              {registeredShow ? (
                                <>
                                  <table className="mb-3">
                                    <tbody>
                                      <tr>
                                        <th>
                                          <img
                                            alt="passport"
                                            src={student?.photo}
                                            style={{ width: "80px" }}
                                          />
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>Name</th>
                                        <td>
                                          {student?.surname +
                                            " " +
                                            student?.othername}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Affiliated School</th>
                                        <td>{student?.affilated_school}</td>
                                      </tr>
                                      <tr>
                                        <th>Matriculation Number</th>
                                        <td>{student?.matric_no}</td>
                                      </tr>
                                      <tr>
                                        <th>Department</th>
                                        <td>{student?.department}</td>
                                      </tr>
                                      <tr>
                                        <th>School/Faculty</th>
                                        <td>{student?.faculty}</td>
                                      </tr>
                                      <tr>
                                        <th>Level</th>
                                        <td>{student?.level}</td>
                                      </tr>
                                      <tr>
                                        <th>Programme</th>
                                        <td>{student?.programme}</td>
                                      </tr>
                                      <tr>
                                        <th>Session</th>
                                        <td>{session}</td>
                                      </tr>
                                      <tr>
                                        <th>Entry</th>
                                        <td>{student?.entry_session}</td>
                                      </tr>
                                      <tr>
                                        <th>Phone Number</th>
                                        <td>{student?.phone}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {registeredCourses.length ? (
                                    <>
                                      <p className="breadcrumb-item">
                                        Registered Courses
                                      </p>
                                      <table className="col-lg-9">
                                        <thead className="dspg-dark">
                                          <th>Subject</th>
                                          <th>Grade</th>
                                          <th>Unit</th>
                                          <th>Semester</th>
                                        </thead>
                                        {registeredCourses?.map((list) => (
                                          <tbody>
                                            <td>{list.code}</td>
                                            <td>{list.title}</td>
                                            <td>{list.unit}</td>
                                            <td>{list.semester}</td>
                                          </tbody>
                                        ))}
                                        <tfoot>
                                          <tr>
                                            <td className="h5">Total Units</td>
                                            <td></td>
                                            <td className="h5">
                                              {totalRegisteredUnits}
                                            </td>
                                            <td></td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                      
                                  <div className="mt-3">
                                    <button
                                      className="btn waves-effect dspg-danger mr-3"
                                      onClick={deleteFirstSemesterCourses}
                                      disabled={loading}
                                    >
                                      Delete First Semester
                                    </button>
                                    <button
                                      className="btn waves-effect dspg-danger"
                                      onClick={deleteSecondSemesterCourses}
                                      disabled={loading}
                                    >
                                      Delete Second Semester
                                    </button>
                                  </div>
                                    </>
                                  ) : (
                                    <div className="alert dspg-info">
                                      No Courses Registered Yet
                                    </div>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default CourseRegDetails;
